import { useRoutes } from 'react-router-dom'
import { AllPages } from './routes'

function App() {
    const allPages = useRoutes(AllPages())
    return (
        <>
            {allPages}
        </>
    )
}

export default App
