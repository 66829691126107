import AppLayout from "../components/layout/AppLayout";
import loadable from '@loadable/component'
import LoginLayout from "components/layout/LoginLayout";

const Login = loadable(() => import('pages/01-login/Login'))
const Home = loadable(() => import('pages/00-homes/Home'))
const AddEvent = loadable(() => import('pages/02-add-event/AddEvent'))
const EditEvent = loadable(() => import('pages/03-edit-event/EditEvent'))

const publicRoutes = [
  {
    index: true,
    path: "/",
    name: "Home",
    element: <Home />
  },
  {
    index: true,
    path: "/add-event",
    name: "AddEvent",
    element: <AddEvent />
  },
  {
    index: true,
    path: "/edit-event/:id",
    name: "EditEvent",
    element: <EditEvent />
  }
]

const loginRoutes = [
  {
    index: true,
    path: "/login",
    name: "Login",
    element: <Login />
  }
]

export function AllPages() {
  return [
    {
      element: <AppLayout />,
      children: [...publicRoutes]
    },
    {
      element: <LoginLayout />,
      children: [...loginRoutes]
    }
  ]
}