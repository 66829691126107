import { useNavigate } from "react-router-dom"
import { Button } from "antd";

const Header = () => {
  const navigate = useNavigate();
  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
    navigate('/login')
  }
  return (
    <div style={{ height: 50, paddingLeft: 50, paddingTop: 10 }}>
      <Button style={{ float: "right", marginRight: 25, marginBottom: 10 }}
        onClick={() => handleLogout()}
      >
        Logout
      </Button>
    </div>
  )
}

export default Header